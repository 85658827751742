import { useFirebase } from '@/composables/config/useFirebase';
import * as Sentry from '@sentry/nuxt';
import dayjs from 'dayjs';

export default defineNuxtRouteMiddleware((to) => {
  const { fbAuth } = useFirebase();
  const { $posthog } = useNuxtApp();

  const checkTokenRefreshTime = async (user) => {
    const tokenResult = await user.getIdTokenResult();
    const authTime = dayjs(tokenResult.authTime);
    const currentTime = dayjs();

    const minutesSinceRefresh = currentTime.diff(authTime, 'minute');

    if (minutesSinceRefresh > 60) {
      // 60 minutes = 1 heure
      await fbAuth.signOut();
      Sentry.setUser(null);
      $posthog.reset();
      return navigateTo('/auth/login');
    }
  };

  fbAuth.onAuthStateChanged(async (user) => {
    if (user) {
      await checkTokenRefreshTime(user);
      return navigateTo(to.path);
    } else {
      fbAuth.signOut();
      Sentry.setUser(null);
      $posthog.reset();
      return navigateTo('/auth/login');
    }
  });
});
